import React from 'react'
import './Block.css'

const Block = () => {
  return (
    <div>
        <div className='app__block app__bg_2'/>
    </div>
  )
}

export default Block